/* basic styles */
.skeleton {
    background-color: #ddd;
    margin: 10px 0;
    border-radius: 4px;
}
.skeleton.text {
    width: 100%;
    height: 12px;
    opacity: .6;
}
.skeleton.title {
    width: 70%;
    height: 20px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
}
.skeleton.price {
    width: 60%;
    height: 20px;
    margin-top: 25px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
}
.skeleton.rate {
    width: 50%;
    height: 15px;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    opacity: .4;
}
.skeleton.button {
    width: 60%;
    height: 45px;
    margin-top: 25px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    opacity: .3;
}
.skeleton.avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    opacity: .4;
    margin-left: auto;
    margin-right: auto;
}
.skeleton.thumbnail {
    width: 100px;
    height: 100px;
    opacity: .6;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}
.skeleton-wrapper {
    margin: 0;
    padding: 0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    /* border-radius: 10px; */
}
.skeleton.item {
    width: 100%;
    height: 12px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    opacity: .5;
}
.skeleton.item-circle {
    width: 30px;
    height: 30px;
    margin-bottom: 0;
    border-radius: 50%;
    opacity: .5;
}
.skeleton.order-heading {
    width: 180px;
    height: 20px;
    border-radius: 4px;
    margin-top: 0;
    margin-bottom: 0;
}
.skeleton.order-text {
    width: 200px;
    height: 12px;
    margin-bottom: 5px;
    opacity: .4;
}
.skeleton.order-mark {
    width: 100px;
    height: 14px;
    margin: 20px 0 5px 0;
    opacity: .6;
}
.skeleton.order-button {
    width: 100%;
    height: 44px;
    margin-top: 0;
    margin-bottom: 0;
    opacity: .5;
}
.skeleton.order-item-title {
    width: 160px;
    height: 12px;
    margin-bottom: 10px;
    opacity: .8;
}
.skeleton.order-item-title-lg {
    width: 160px;
    height: 16px;
    margin-bottom: 10px;
    opacity: .8;
}
.skeleton.order-item-price {
    width: 120px;
    height: 12px;
    margin-bottom: 5px;
    opacity: .3;
}
.skeleton.order-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    opacity: .6;
}
.skeleton.order-price {
    width: 30px;
    height: 12px;
    margin-bottom: 10px;
    opacity: 1;
}
.skeleton.order-price-lg {
    width: 30px;
    height: 16px;
    margin-bottom: 10px;
    opacity: 1;
}

.skeleton.banner-heading {
    width: 240px;
    height: 25px;
    border-radius: 4px;
    margin-top: 0;
    margin-bottom: 1.5rem;
    background-color: #C4C4C4;
    opacity: .6;
}
.skeleton.banner-text {
    width: 100%;
    height: 12px;
    margin-bottom: 5px;
    background-color: #C4C4C4;
    opacity: .3;
}
.skeleton.banner-text-short {
    width: 70%;
    height: 12px;
    margin-bottom: 5px;
    background-color: #C4C4C4;
    opacity: .3;
}
.skeleton.banner-button {
    display: inline-block;
    width: 200px;
    height: 48px;
    margin: 0 10px 0 0;
    background-color: #C4C4C4;
    opacity: .6;
}
.skeleton.banner-avatar {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0;
    background-color: #C4C4C4;
    opacity: .6;
}
@media (max-width: 991.98px) {
    .skeleton.banner-heading,
    .skeleton.banner-text,
    .skeleton.banner-text-short {
        margin-left: auto;
        margin-right: auto;
    }
    .skeleton.banner-text {
        width: 90%;
    }
    .skeleton.banner-text-short {
        width: 60%;
    }
}

@media (max-width: 767.98px) {
    .card-list {
        padding-bottom: 0 !important;
        margin-bottom: 1.25rem !important;
    }
    .card-list .title,
    .card-list .price,
    .card-list .rate,
    .card-list .button {
        margin: 0;
    }
    .card-list .button {
        width: 130px;
    }
    .card-list .skeleton.avatar {
        width: 100px;
        height: 100px;
        margin: 1rem 1rem;
    }
}

/* skeleton profile */
.skeleton-profile {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
    align-items: center;
}

/* themes */
.skeleton-wrapper.transparent {
    background-color: transparent;
}
.skeleton-wrapper.light .card {
    background-color: #f2f2f2;
}
.skeleton-wrapper.dark .card {
    background-color: #444;
}
.skeleton-wrapper.light-dark .card {
    background-color: #777;
}
.skeleton-wrapper.dark .card,
.skeleton-wrapper.light-dark .card {
    border: none;
}

/* animation effects */
.shimmer-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation: loading 2.5s infinite;
}
.shimmer {
    width: 50%;
    height: 100%;
    background-color:rgba(255, 255, 255, .2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, .05);
}
.dark .shimmer,
.light-dark .shimmer {
    background-color:rgba(255, 255, 255, .05);
}
.shimmer-banner {
    width: 50%;
    height: 100%;
    background-color:rgba(245, 239, 224, .2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(245, 239, 224, .5);
}
@keyframes loading {
    0% {transform: translateX(-150%);}
    50% { transform: translateX(-60%);}
    100% {transform: translateX(150%);}
}