html,body {
    font-family: 'Noto Sans TC', 'Muli', 'Open Sans', 'Raleway', 'Merriweather', sans-serif;
}
.ribbon span {
    font-size: 9px;
}
.top-min-2 {
    top: -2px;
}
.fw-light {
    font-weight: 300;
}
.x-small {
    font-size: 78%;
}
strong, b {
    font-weight: 700;
}
.navbar-nav .nav-item:last-child .nav-link {
    padding-right: 0;
}
.slider-nav .slick-arrow {
    top: calc(50% - 21px);
}
.btn-pointer {
    cursor: default !important;
}
header {
    background-color: #fff;
    padding: 0;
}
footer {
    border-top: 1px solid #e9e9e9;
}
main,
.main {
    min-height: auto;
    margin-top: 88px;
}
.img-bw {
    filter: grayscale(100%);
}
.border-bottom {
    border-bottom: 1px solid #e9e9e9 !important;
}
.btn-no-pad {
    padding: 0 !important;
}
.btn-flat:hover,
.btn-flat:focus {
    box-shadow: none !important;
}
.btn-link:hover,
.btn-link:focus {
    text-decoration: none;
}
.custom-select-cartitem {
    min-width: 70px;
}
.opacity-25 {
    opacity: .25;
}
.opacity-50 {
    opacity: .5;
}
.opacity-75 {
    opacity: .75;
}
.pic-border {
    border: 1px solid #eee;
}
.nav-shadow {
    box-shadow: 0 1px 10px 0 rgba(0,0,0,.1) !important;
}
header nav {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
@media (min-width: 768px) {
    .slider-nav .slick-arrow {
        top: calc(50% - 27px);
    }
}
@media (min-width: 992px) {
    header nav {
        padding-top: 2.4rem !important;
        padding-bottom: 2.4rem !important;
    }
    main,
    .main {
        margin-top: 133px;
    }
}

/* slick */
.slider-for {
    margin-bottom: 30px;
}
.slider-for .slick-dots {
    margin: 0;
}

/* slider gap */
.slick-slider {
    padding: 20px 0 40px;
}
.slick-slider .slick-dots {
    margin-top: 0;
}
@media (min-width: 992px) {
    .slick-slider {
        padding: 10px 0 40px;
    }
}

/* animations */
.fadeinDown {
    -webkit-animation: fadeInDown 500ms ease-in-out; /* Chrome, Safari, Opera */
    animation: fadeInDown 500ms ease-in-out;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

/* Standard syntax */
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeinUp {
    -webkit-animation: fadeInUp 500ms ease-in-out; /* Chrome, Safari, Opera */
    animation: fadeInUp 500ms ease-in-out;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

/* Standard syntax */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeIn {
    -webkit-animation: fadeIn 500ms ease-in-out; /* Chrome, Safari, Opera */
    animation: fadeIn 500ms ease-in-out;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
        -webkit-transform: translateX(0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

/* Standard syntax */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(0);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInLeft {
    -webkit-animation: fadeInLeft 500ms ease-in-out; /* Chrome, Safari, Opera */
    animation: fadeInLeft 500ms ease-in-out;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(80px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

/* Standard syntax */
@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(80px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInRight {
    -webkit-animation: fadeInRight 500ms ease-in-out; /* Chrome, Safari, Opera */
    animation: fadeInRight 500ms ease-in-out;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

/* Standard syntax */
@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(-80px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeOut {
    -webkit-animation: fadeOut 500ms ease-in-out; /* Chrome, Safari, Opera */
    animation: fadeOut 500ms ease-in-out;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(0);
    }
}

/* Standard syntax */
@keyframes fadeOut {
    0% {
        opacity:1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateY(0);
    }
}

.profile-login-icon {
    width: 30px;
    height: 30px;
    position: relative;
    top: 0;
    right: 0;
    margin-right: 6px;
    border-radius: 50%;
}

/* Move reCAPTCHA v3 badge to the left */
.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 0px !important;
    bottom: 15px !important
}
.grecaptcha-badge:hover {
    width: 256px !important;
}

/* Toastify 
* https://fkhadra.github.io/react-toastify/how-to-style/
*/

.Toastify__close-button {
    margin-top: -5px;
}
.Toastify__toast-container {
    width: 100% !important;
    min-width: 310px !important;
}
@media (min-width: 576px) {
    .Toastify__toast-container {
        width: 90% !important;
    }
}
@media (min-width: 768px) {
    .Toastify__toast-container {
        width: auto !important;
    }
}

/* spinner */
.spinner-form-button {
    width: 20px;
    height: 20px;
    border-width: 2px;
    margin: 1px 10px 3px 0;
}
.spinner-form-button-sm {
    margin: 1px 10px 0 0;
}

/* cart */

.cart-qty {
    min-width: 1.2rem;
    min-height: 1.2rem;
    /* top: 0px; */
    top: -8px;
    right: -4px;
    line-height: 12px;
}
.cart-price {
    position: absolute;
    left: 95px;
    bottom: 11px;
    font-size: 10px;
    font-weight: 500;
    /* color: rgba(0,0,0,.5); */
    color: #dc143c;
    display: none;
}
.cart-price:hover,
.cart-price:focus {
    /* color: rgba(0,0,0,.8);  */
    color: #dc143c;
}
@media (min-width: 992px) {
    .cart-price {
        /* left: 11px;
        bottom: -8px; */
        left: 10px;
        bottom: 33px;
    }
}

.before-top-min-2:before {
    position: relative;
    top: -2px;
}
.after-top-min-2:after {
    position: relative;
    top: -2px;
}
.before-top-min-3:before {
    position: relative;
    top: -3px;
}
.after-top-min-3:after {
    position: relative;
    top: -3px;
}
.before-top-min-4:before {
    position: relative;
    top: -4px;
}
.after-top-min-4:after {
    position: relative;
    top: -4px;
}

.before-top-min-6:before {
    position: relative;
    top: -6px;
}

.btn-default {
    border: 1px solid #dee2e6;
}



/* remove shadow from cards and from navbar */
.card,
.navbar,
.shadow-sm,
.shadow,
.shadow-lg,
.dropdown-menu,
.slick-slider .slick-next, 
.slick-slider .slick-prev,
.Toastify__toast,
.btn {
    box-shadow: none !important;
}
.navbar {
    border-bottom: 3px solid #dc143c !important;
}
.border-top {
    border-top: 1px solid #e9e9e9 !important;
}
@media (min-width: 992px) {
    .dropdown-menu {
        border: 1px solid #e9e9e9 !important;
        min-width: 200px;
    }
}

/* sizes */
.display-4 {
    font-size: 2.8rem;
}
@media (min-width: 576px) {
    .display-4 {
        font-size: 3rem;
    }
}
@media (min-width: 768px) {
    .display-4 {
        font-size: 3.5rem;
    }
}

/* paging */
.paging {
    padding: 1em 0;
    display: flex;
    justify-content: center;
}
.paging-carrier {
    /* box-shadow: 0 0.1rem 0.2rem 0 rgb(0 0 0 / 5%); */
    border-radius: 50px;
    border: 1px solid #dee2e6;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    width: 100%;
    overflow: hidden;
}
.paging-carrier a {
    width: 100%;
}
.paging-carrier svg {
    height: 34px;
    width: 34px;
}
.paging-carrier div:first-child {
    border-right: 1px solid #f5f5f5;
}
.paging-carrier div:nth-child(3) {
    border-left: 1px solid #f5f5f5;
}
.paging-carrier > div {
    width: 73px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.paging-carrier span {
    color: #333;
    font-size: 21px;
    font-weight: 600;
}
.paging-carrier a {
    padding: 0;
    margin: 0;
    line-height: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.paging-carrier a:hover {
    background-color: rgba(0, 0, 0, .02);
}
.paging-carrier a:hover svg {
    stroke:#000
}

/* pagination */
/* .pagination .page-link {
    min-width: 35px;
    width: 3rem;
    height: 3rem;
    line-height: 27px;
} */
.pagination .page-item-dots .page-link {
    margin-right: -7px;
}
.pagination .page-link {
    margin: 0;
}
.pagination .page-item {
    margin: 0 4px;
}

.custom-select-medium {
    height: calc(1.5em + 1rem + 2px);
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 1.15rem;
}
@media (min-width: 768px) {
    .custom-select-medium {
        font-size: 1rem;
    }
}

.show-password-icon {
    top: .475rem !important;
}

/* radio button */
.custom-control-label-radio::before,
.custom-control-label-radio::after {
    top: 0.30rem;
}
.custom-control-input:checked ~ .custom-control-label::before {
    border-color: transparent;
}

/* form */
/* .form-control:focus,
.form-check-input:focus {
  border-width: 2px !important;
  box-shadow: none !important;
} */
.form-control.is-invalid,
.form-control.is-valid {
  background-image: none !important;
}
.invalid-feedback {
    color: #ff0000;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #999 !important;
    font-size: 18px !important;
    font-weight: 300 !important;
    opacity: 1; /* Firefox */
}
input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #999 !important;
    font-size: 18px !important;
    font-weight: 300 !important;
}
input::-ms-input-placeholder { /* Microsoft Edge */
    color: #999 !important;
    font-size: 18px !important;
    font-weight: 300 !important;
}

.bi-search::before {
    position: relative;
    top: 2px;
}

/* nav */
.nav-segment .nav-link {
    padding: .675rem .875rem;
}
@media (min-width: 768px) {
    .nav-segment .nav-link {
        padding: .475rem .875rem;
    } 
}
@media (max-width: 767.98px) {
    .nav-pills {
        width: calc(100% + 3rem);
        margin-left: -1.5rem;
        margin-top: -1.5rem;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .nav-segment {
        padding: 0;
    }
    .nav-segment .nav-item:first-child .nav-link {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .nav-segment .nav-link {
        border-radius: 0;
    }
}

/* checkout */
.checked::before {
    position: relative;
    font-family: 'bootstrap-icons';
    content: "\f26a";
    font-weight: 300;
    color: #dc143c;
    margin-right: 8px;
    top: 4px;
    left: -2px;
    font-size: 18px;
    line-height: 0;
}
.step::before {
    position: relative;
    content: "";
    font-weight: 400;
    margin-right: 8px;
    left: 2px;
    line-height: 0;
}
.step.step1::before { content: "1."; }
.step.step2::before { content: "2."; }
.step.step3::before { content: "3."; }
.step.step4::before { content: "4."; }
.step.active::before {font-weight: 700; }

.text-success {
    color: rgba(7, 188, 12, .75) !important;
}
.text-danger {
    color: rgba(223, 20, 60, .75) !important;
}
.bg-success {
    background: rgba(7, 188, 12, .75) !important;
}
.bg-danger {
    background: rgba(223, 20, 60, .75) !important;
}
.ribbon span.new-arrival {
    background: rgba(7, 188, 12, .75);
    background: linear-gradient(rgba(7, 188, 12, .75),rgba(7, 188, 12, .85));
}
.ribbon span.new-arrival::before {
    border-left: 3px solid rgba(7, 188, 12, .75);
    border-top: 3px solid rgba(7, 188, 12, .75);
}
.ribbon span.new-arrival::after {
    border-right: 3px solid rgba(7, 188, 12, .75);
    border-top: 3px solid rgba(7, 188, 12, .75);
}
.ribbon span.sold-out {
    background: rgba(223, 20, 60, .75);
background: linear-gradient(rgba(223, 20, 60, .75),rgba(223, 20, 60, .85));
}
.ribbon span.sold-out::before {
    border-left: 3px solid rgba(223, 20, 60, .75);
    border-top: 3px solid rgba(223, 20, 60, .75);
}
.ribbon span.sold-out::after {
    border-right: 3px solid rgba(223, 20, 60, .75);
    border-top: 3px solid rgba(223, 20, 60, .75);
}

/* .card img {
    width: 100px !important;
} */
.profile-img {
    width: 100px !important;
}
.card .card-img {
    width: 180px !important;
}
.card .cart-img {
    width: 130px !important;
}

.card .btn-cart::before {
    position: relative;
    font-family: 'bootstrap-icons';
    content: "\f244";
    font-weight: 300;
    margin-right: 8px;
    top: 2px;
    left: 0;
    font-size: 18px;
    line-height: 0;
}

@media (max-width: 767.98px) {
    .card-list {
        flex-direction: row;
        padding-top: 0;
        align-items: start;
    }
    .card-list .card-body {
        padding: 1rem 1rem 1rem 0 !important;
    }
    .card-list .card-img {
        max-width: 100px !important;
    }
    .card .cart-img {
        width: 180px !important;
    }
    .card-list > a {
        padding: 1rem 1rem;
    }
    .card-list .btn {
        padding: 0;
        border: none;
        text-transform: lowercase;
    }
    .card-list .btn:hover {
        color: #b91132;
        background-color: transparent !important;
        border-color: transparent !important;
    }
    .card-list .btn-cart::before {
        position: relative;
        font-family: 'bootstrap-icons';
        content: "\f244";
        font-weight: 300;
        color: #dc143c;
        margin-right: 8px;
        top: 2px;
        left: 0;
        font-size: 18px;
        line-height: 0;
    }
    .card-list .like,
    .card-list .like-swap {
        right: 1rem;
        left: unset;
        top: unset;
        bottom: 1rem;
    }
}

/* .band {
    background-color: #f8ebd5;
} */

/* payment */
.btn-creditcard i {
    position: relative;
    top: 0;
    font-size: 24px;
    line-height: 0;
    margin-right: 8px;
}

.pagination-square .before-top-min-3::before {
    top: -3.5px;
}
.pagination-square .page-link {
    padding: 3px 10px 4px;
    font-size: 14px;
    min-width: 34px;
}
.pagination-square .page-item {
    margin: 0 3px;
}
.pagination-square .bi-chevron-left::before,
.pagination-square .bi-chevron-right::before {
  font-size: 12px;
}

.bi-status-align {
    position: relative;
    top: 0;
    font-size: 22px;
    line-height: 0;
}

.opacity-10 {
    opacity: .1;
}
.opacity-15 {
    opacity: .15;
}

/* footer cart */
.cart-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 62px;
    background: #f5efe0;
    z-index: 3;
    border-top: 3px solid #dc143c;
}
.cart-footer > a {
    padding: 0 1.25rem;
}
.cart-footer-link {
    display: block;
    padding: .5rem 0;
}
.cart-footer-icon {
    font-size: 1.3rem;
    margin-right: .25rem;
}
.cart-footer-dark {
    background: #dc143c;
    border-color: #dc143c;  
}
.cart-footer-dark .cart-footer-link,
.cart-footer-dark .cart-footer-link:hover,
.cart-footer-dark .cart-footer-link:focus,
.cart-footer-dark .cart-footer-link:active {
    color: #fff !important;
}
.cart-footer-dark .badge {
    background: #fff !important;
    color: #121416;
}
.back-top {
    bottom: 70px;
}
@media (min-width: 992px) {
    .cart-footer {
        display: none;
    }
    .back-top {
        bottom: 15px;
    }
}

.tr-border-bottom {
    border-bottom: 1px solid #efefef;
}

.custom-checkbox-label::before,
.custom-checkbox-label::after {
    top: .32rem;
}

.action-align::before {
    top: -2px;
    position: relative;
    padding-right: 6px;
}

.btn-group-sm > .btn, .btn-sm {
    padding: .525rem 1.125rem;
    border-radius: 5px;
}


/* react dual listbox */
.react-dual-listbox button, .react-dual-listbox select {
    line-height: 1.82857 !important;
    font-family: inherit;
}
.rdl-actions-right {
    margin-bottom: 0 !important;
}
.rdl-filter,
.rdl-control,
.rdl-move {
    border-radius: 5px !important;
    border: 1px solid #dee2e6 !important;
}
.rdl-move {
    border-radius: 0 !important;
}
.rdl-control {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.rdl-move-right:first-child,
.rdl-move-left:first-child {
    border-top-left-radius: 5px !important;
}
.rdl-move-right:last-child,
.rdl-move-left:last-child {
    border-top-right-radius: 5px !important;
}
.rdl-filter {
    height: 42px !important;
}
.rdl-move {
    height: 40px;
    border-bottom-width: 0 !important;
}
.rdl-align-top .rdl-list-box .rdl-move:first-child {
    border-right: 0 !important;
}
@media (max-width: 575.98px) {
    .react-dual-listbox {
        display: block !important;
    }
    .rdl-align-top .rdl-available {
        margin-right: 0 !important;
        margin-bottom: 25px !important;
    }
    .rdl-align-top .rdl-selected {
        margin-left: 0 !important;
    }
}

/* react select */
.css-1ko62if-control {
    min-height: 42px !important;
    border: 1px solid #dee2e6 !important;
}
.css-1o70pz1-multiValue {
    background-color: #f5efe0 !important;
    padding-left: 2px !important;
}
.rdl-control option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
}
.css-1okebmr-indicatorSeparator {
    background-color: transparent !important
}
.css-xrpreq:hover {
    background-color: #dc143c !important;
    color: #fff !important;
}
.css-g1d714-ValueContainer {
    padding: 2px 3px !important;
}

/* modal */
.modal-footer-align {
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    margin-top: 2rem;
}

.x-small {
    font-size: 80%;
}

.before-top-1::before {
  position: relative;
  top: 1px;
}
.before-top-2::before {
  position: relative;
  top: 2px;
}
.before-top-3::before {
  position: relative;
  top: 3px;
}
.before-top-4::before {
  position: relative;
  top: 4px;
}
.before-top-5::before {
  position: relative;
  top: 5px;
}


/* added marge for dots banner */
.slick-slider {
    padding: 10px 0 60px;
}
.slick-slider .slick-dots {
    margin-top: 0;
    padding-top: 1.5rem;
}
.product-detail-carousel .slick-slider .slick-dots {
    padding-top: 0;
}

.bi-search-clear::before {
    content: "\F659";
}
.bi-search-clear {
    cursor: pointer;
}

.text-black {
    color: #212529;
}
.text-black:hover,
.text-black:focus {
    color: #000;
}

.like-unstyle {
    position: unset;
    right: 0;
    top: 0;
}

.removable-input {
    position: relative;
}
.removable-input span {
    display: block;
    cursor: pointer;
}
.removable-input input {
    padding-right: 3rem;
}
.removable-input span::after {
    position: absolute;
    font-family: 'bootstrap-icons';
    content: "\f622";
    font-weight: 300;
    color: #dc143c;
    margin-right: 8px;
    top: 25px;
    right: 8px;
    font-size: 22px;
    line-height: 0;
}

/* @media (max-width: 767.98px){
    .custom-file-label::after {
        content: "\F4B3" !important;
    }
} */

.carousel-image {
    width: 90% !important;
}
@media (min-width: 768px){
    .carousel-image {
        width: 75% !important;
    } 
}

.link-underline-primary {
    color: #dc143c;
    border-bottom: .0625rem dashed #dc143c;
}

.modal-open .modal {
    z-index: 999999999 !important;
}

.container-overlap {
    z-index: unset;
}